import React, { useRef, useEffect } from 'react';


const Preview = ({ selectedPhoto }:any) => {
  const containerRef:any = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      const container = containerRef.current;
      const aspectRatio = 1024 / 768;
      let containerHeight = window.screen.height * 0.57
      let containerWidth = containerHeight/aspectRatio;
      if (window.screen.height > window.screen.width) {
        containerWidth = window.screen.width * 0.9
        containerHeight = containerWidth*aspectRatio;  
      }
      container.style.width = `${containerWidth}px`;
      container.style.height = `${containerHeight}px`;
  };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div ref={containerRef} style={{ width: '768px', height: '1024px',overflow: 'hidden', boxShadow: '0 0 20px rgba(0,0,0,0.5)' }}>
      <img src={`data:image/jpeg;base64,${selectedPhoto}`} alt="Selected" style={{ width: '100%', height: '100%', objectFit: 'cover', backgroundColor: 'black' }} />
    </div>
  );
};

export default Preview;
