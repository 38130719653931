import React from 'react';
import { Box } from '@mui/material';

const Thumbnails = ({ images, onImageSelect }: { images: string[], onImageSelect: (image: string) => void }) => {
  return (
    <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
      {images.map((image, index) => (
        <Box key={index} style={{ margin: '5px' }}>
          <img
            src={`data:image/jpeg;base64,${image}`}
            alt={`Thumbnail ${index}`}
            style={{ width: '100px', height: '100px', objectFit: 'cover', cursor: 'pointer' }}
            onClick={() => onImageSelect(image)}
          />
        </Box>
      ))}
    </Box>
  );
};

export default Thumbnails;
