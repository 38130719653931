import React, { useRef, useEffect, useState } from 'react';

const CameraFeed = ({ videoRef, streamRef }:any) => {
  const containerRef:any = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      const container = containerRef.current;
      const aspectRatio = 1024 / 768;
      let containerHeight = window.screen.height * 0.57
      let containerWidth = containerHeight/aspectRatio;
      if (window.screen.height > window.screen.width) {
        containerWidth = window.screen.width * 0.9
        containerHeight = containerWidth*aspectRatio;  
      }
      container.style.width = `${containerWidth}px`;
      container.style.height = `${containerHeight}px`;
  };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div ref={containerRef} style={{ width: '768px', height: '1024px',overflow: 'hidden', boxShadow: '0 0 20px rgba(0,0,0,0.5)' }}>
      <video ref={videoRef} autoPlay muted style={{ width: '100%', height: '100%', transform: 'scaleX(-1)', objectFit: 'cover', backgroundColor: 'black' }} />
    </div>
  );
};

export default CameraFeed;
