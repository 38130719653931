import React, { useState, useRef, useEffect } from 'react';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, LinearProgress } from '@mui/material';
import CameraFeed from './CameraFeed';
import Preview from './Preview';
import Thumbnails from './Thumbnails';
import Fab from '@mui/material/Fab';
import RefreshIcon from '@mui/icons-material/Refresh';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { callApiSubmitFrameSelectionTask, callApiGetFrameSelectionTaskStatus, API_SUCCESS, API_ERROR } from '../common/api';

const messages = [
    "Stand Tall",
    "Step Back",
    "Center Yourself",
    "Please move a bit closer",
    "Perfect Pose",
    "Stay Still",
    "Analyzing...",
    "We got you, you can move now! <br> Your outfit result will appear in seconds!",
];

const VIDEO_RECORDING_DURATION_MS = 2000;
const VIDEO_RECORDING_DELAY = 5000;

const VideoFrameSelectionDialog = ({ handleClose, open, handleSelectFrame }: any) => {
    const [isFrameSelectionRunning, setIsFrameSelectionRunning] = useState(false);
    const [outputImages, setOutputImages] = useState<string[]>([]);
    const [selectedPhoto, setSelectedPhoto] = useState<string | null>(null);
    const [message, setMessage] = useState(messages[0]);
    const [progress, setProgress] = useState(0);
    const [showProgressBar, setShowProgressBar] = useState(false);

    const videoRef = useRef<HTMLVideoElement | null>(null);
    const streamRef = useRef<MediaStream | null>(null);
    const recordedChunks = useRef<Blob[]>([]);
    const timerRef = useRef<NodeJS.Timeout | null>(null);

    const runFrameSelection = async () => {
        setMessage(messages[0]);
        setSelectedPhoto('');
        setIsFrameSelectionRunning(true);

        await new Promise(resolve => {
            timerRef.current = setTimeout(resolve, VIDEO_RECORDING_DELAY);
        });

        setMessage(messages[5]);
        if (streamRef.current) {
            const mediaRecorder = new MediaRecorder(streamRef.current);
            recordedChunks.current = [];

            mediaRecorder.ondataavailable = (event: BlobEvent) => {
                if (event.data.size > 0) {
                    recordedChunks.current.push(event.data);
                }
            };
            mediaRecorder.onstop = async () => {
                setMessage(messages[6]);
                try {
                    const submitResponse = await callApiSubmitFrameSelectionTask(recordedChunks);
                    const taskId = submitResponse.data.task_id;
                    setShowProgressBar(false);
                    if (submitResponse.status === API_SUCCESS) {
                        const checkTaskStatus = async () => {
                            try {
                                const statusResponse = await callApiGetFrameSelectionTaskStatus(taskId);
                                console.log(statusResponse)
                                if (statusResponse.status === API_SUCCESS) {
                                    if (statusResponse.data.state === 'COMPLETED') {
                                        if (statusResponse.data.selected_frames.length) {
                                            console.log(statusResponse.data.selected_frames.map((v: any) => { return { filename: v.filename, score: v.score } }))
                                            setOutputImages(statusResponse.data.selected_frames.map((v: any) => v.subject_photo_b64))
                                            setSelectedPhoto(statusResponse.data.selected_frames[0].subject_photo_b64)
                                            setIsFrameSelectionRunning(false);
                                            setMessage('');
                                            setShowProgressBar(false);
                                        }
                                        setShowProgressBar(false);
                                        setMessage('');
                                    } else {
                                        if (statusResponse.data.state === 'PROGRESS') {
                                            setProgress(statusResponse.data.info.progress);
                                        }
                                        timerRef.current = setTimeout(checkTaskStatus, 1000);
                                    }
                                } else if (statusResponse.status === API_ERROR) {
                                    setMessage('An error occurred. Please try again.');
                                    setShowProgressBar(false);
                                    timerRef.current = setTimeout(runFrameSelection, 2000);
                                } else {
                                    timerRef.current = setTimeout(checkTaskStatus, 1000);
                                }
                            } catch (error) {
                                setMessage('An error occurred. Please try again.');
                                setShowProgressBar(false);
                                console.error('Error during task status API call:', error);
                            }
                        };
                        checkTaskStatus();
                    } else {
                        console.log(submitResponse.message);
                        setMessage(submitResponse.message);
                    }
                } catch (error: any) {

                }
            };

            mediaRecorder.start();
            timerRef.current = setTimeout(() => {
                mediaRecorder.stop();
            }, VIDEO_RECORDING_DURATION_MS);

            await new Promise<void>((resolve) => {
                const originalOnStop = mediaRecorder.onstop;
                mediaRecorder.onstop = async (event) => {
                    if (originalOnStop) {
                        await originalOnStop.call(mediaRecorder, event);
                    }
                    resolve();
                };
            });

            setIsFrameSelectionRunning(false);
        }
    };

    const resetDialog = () => {
        setSelectedPhoto(null);
        setMessage(messages[0]);
        setIsFrameSelectionRunning(false);
        if (streamRef.current) {
            streamRef.current.getTracks().forEach((track) => track.stop());
            streamRef.current = null;
        }
        if (timerRef.current) {
            clearTimeout(timerRef.current);
            timerRef.current = null;
        }
    };

    const setupCamera = async () => {
        try {
            // Define constraints for Full HD video
            const constraints = {
                video: true
                // {
                //     width: { ideal: 1920 },
                //     height: { ideal: 1080 }
                // }
            };
            const mediaStream = await navigator.mediaDevices.getUserMedia(constraints);
            streamRef.current = mediaStream;
            if (videoRef.current) {
                videoRef.current.srcObject = mediaStream;
            }
            runFrameSelection();
        } catch (error) {
            console.error('Error accessing camera:', error);
        }
    };

    useEffect(() => {
        if (open === true) {
            setupCamera();
        } else {
            resetDialog();
        }
        return () => {
            resetDialog();
        };
    }, [open]);

    const handleThumbnailClick = (image: string) => {
        setSelectedPhoto(image);
    };

    const handleTryAgainClick = () => {
        setOutputImages([]);
        setSelectedPhoto(null);
        setupCamera();
    }

    const handleSelectSubjectPhoto = () => {
        let selectedFrame = selectedPhoto;
        resetDialog();
        handleClose();
        handleSelectFrame(selectedFrame)
    }

    return (
        <Dialog open={open} onClose={() => { resetDialog(); handleClose(); }} fullScreen>
            <DialogTitle style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            </DialogTitle>
            <DialogContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', height: '100%' }}>
                {selectedPhoto && !isFrameSelectionRunning ? (
                    <>
                        <Box style={{ flexGrow: 7, display: 'flex', justifyContent: 'center', alignItems: 'center', width: 'auto', flexDirection: 'column', position: 'relative' }}>
                            <Box style={{ display: 'flex', justifyContent: 'space-between', position: 'relative', alignItems: 'center', width: '100%', top: '60px' }}>
                                <Fab color="primary" aria-label="add" onClick={handleTryAgainClick} sx={{ mr: 1, position: 'absolute', left: '20px' }}>
                                    <RefreshIcon />
                                </Fab>
                                <Fab color="primary" aria-label="add" onClick={handleSelectSubjectPhoto} sx={{ mr: 1, position: 'absolute', right: '20px' }}>
                                    <CheckCircleIcon />
                                </Fab>
                            </Box>
                            <Preview selectedPhoto={selectedPhoto} />
                        </Box>
                        <Box style={{ flexGrow: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', width: 'auto' }}>
                            <Thumbnails images={outputImages} onImageSelect={handleThumbnailClick} />
                        </Box>
                    </>
                ) : (
                    <Box style={{ flexGrow: 8, display: 'flex', justifyContent: 'center', alignItems: 'center', width: 'auto' }}>
                        <CameraFeed videoRef={videoRef} streamRef={streamRef} />
                    </Box>
                )}
                <Typography variant="h6" style={{ flexGrow: 1, width: '40%', textAlign: 'center', marginTop: '5px' }}>
                    <span dangerouslySetInnerHTML={{ __html: message }} />
                </Typography>
                {showProgressBar && (
                    <Box sx={{ width: '35%', marginTop: '5px' }}>
                        <LinearProgress variant="determinate" value={progress} />
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { resetDialog(); handleClose(); }} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default VideoFrameSelectionDialog;
